exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-accounting-index-tsx": () => import("./../../../src/pages/accounting/index.tsx" /* webpackChunkName: "component---src-pages-accounting-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplace-index-tsx": () => import("./../../../src/pages/marketplace/index.tsx" /* webpackChunkName: "component---src-pages-marketplace-index-tsx" */),
  "component---src-pages-tokenomics-index-tsx": () => import("./../../../src/pages/tokenomics/index.tsx" /* webpackChunkName: "component---src-pages-tokenomics-index-tsx" */)
}

